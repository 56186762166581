

import React, { useEffect, useState } from 'react' 

import {Box, Stack, Paper, Button, IconButton } from '@mui/material'
import { Grow } from '@mui/material'
import AssignmentIcon from '@mui/icons-material/Assignment'


import BRFTypography from '../../components/BRFTypography'

import { MintButton, RegularButton } from '../../components/BRFButtons'
import MintDialog from '../../components/mintComponents/MintDialog'
import { isMobile } from '../../utils/MobileUtils'
import Legal from '../../components/Legal.js'

import { getLocalAccessToken, getTermsAccepted } from '../../session/backend_session/Backend'
import { getConnectedWallet } from '../../session/WalletSession'


const Dashboard = (isAuthenticated) => {
    const [ isTermAccepted, setIsTermAccepted ] = useState(false)
    const [termsOpen, setTermsOpen] = useState(false);
    const [alertStatus, setAlertStatus] = useState(false)
    const [alertMessage, setAlertMessage] = useState(null)
    const [alertIndicator, setAlertIndicator] = useState(null)



    const [ mintDialogStatus, setMintDialogStatus ] = useState(false)


    const handleMintDialog = () => { 
        setMintDialogStatus(!mintDialogStatus)
    }

    const displayAlert = (status, indicator, message) => {

        setAlertStatus(status)
        setAlertMessage(message)
        setAlertIndicator(indicator)
    }
  

      //Legal Dialog
  const handleLegal = () => {
    if(!isTermAccepted) {
      setTermsOpen(true);
    } 
  }


  /* Terms Handlers */
  const termsHandler = () => { 
    getTermsAccepted()
    .then(res => {
      setIsTermAccepted(res.data); 
      setTermsOpen(false);
    })
    .catch(err => {
      console.log(err);
    })

  }

  useEffect(() => {

  
    const _loadApplication = () => { 
       
      // check authentication 
      if(!isAuthenticated){ 
        return
      }
      // alerts 
      displayAlert(true, 'success', `Connected with :  ${getConnectedWallet()}`)
    }

    const checkTermsAccepted = async () => { 
      if(isAuthenticated){
        if(open){
          await getTermsAccepted()
            .then((res) => { 
              setIsTermAccepted(res.data)
            })
            .catch(() => { 
              console.error('Error With Terms Status')
            })
        }
      }
    }
  

    _loadApplication() 
    checkTermsAccepted()


  }, [isAuthenticated])

    return (
        <Box mt={-6}>

            <Stack 
                direction="column"
                spacing={3}
                justifyContent="center"
                alignItems="center">
                <BRFTypography variant="h6" text="Dashboard"/>



                <Grow 
                    in={true}
                    style={{ transformOrigin: '0 0 0'}}
                    {...(true ? { timeout: 1000} : {} )}>
                    <Paper 
                        elevation={3} 
                        sx={{ 
                                backgroundColor: '#111322', width: { xs: '350px', sm: '100%', md: '70vh', lg: '150vh'}, maxWidth: '150vh',
                                height:  {xs: '650px', sm: '650px', md: ' 75vh'}, maxHeight: '75vh', borderRadius: '10px', overflow: 'auto',
                                justifyContent: 'center', alignItems: 'center', display: 'flex'
                            }}>

                                <Stack 
                                    direction="column"
                                    spacing={2}
                                    justifyContent="center"
                                    alignItems="center" 
                                    display="flex">

                                        {isMobile() ? (
                                            !isTermAccepted ?
                                                <IconButton
                                                    sx={{ 
                                                        backgroundColor: '#003153', color: '#fff',
                                                        ":hover": {
                                                            backgroundColor: '#194564'
                                                        }
                                                      }} 
                                                    size="large"
                                                    aria-label="terms agreement"
                                                    color="inherit"
                                                    onClick={handleLegal}>
                                                    <AssignmentIcon />
                                                </IconButton>
                                              :
                                        
                                            <Box>                                                
                                                <MintButton
                                                    text="Mint"
                                                    onClick={handleMintDialog}/>
                                                
                                            </Box>
                                        )
                                        
                                        : (

                                            <BRFTypography
                                                text="News, Latest Events and etc will be display here"/>

                                        )}

                                      
                                </Stack>
                                


                                
                    </Paper>
                    
                </Grow>


            </Stack>

            {!isTermAccepted && 
          <Legal
              open={termsOpen}
              termsHandler={termsHandler}
              onClose={() => setTermsOpen(false)}
            />
        }


            <MintDialog 
                status={mintDialogStatus} 
                handler={handleMintDialog}/>


        </Box>
    )
}

export default Dashboard 