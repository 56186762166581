import React, { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { RegularButton } from '../../components/BRFButtons';
import FaqDialog from './FAQDialog';
import VisionDialog from './VisionDialog'; 


const articles = [
    { title: 'White Pages', href: 'https://app.gitbook.com/o/KIzeR2vUadV30RoYqWck/s/cV0yOtEFtn6VL0Oy51S5/introduction-and-why'},
    { title: 'Metaknow.org', href: 'https://metaknow.org/bullrush-uniting-american-football-rpgs-and-nfts/'},
];

const DiscoverBullRush = () => {
    const [openFaq, setOpenFaq] = useState(false);
    const [openVision, setOpenVision] = useState(false);


        const handleOpenFaq = () => {
        setOpenFaq(true);
        }

        const handleCloseFaq = () => {
        setOpenFaq(false);  
        }

        const handleOpenVision = () => {
            setOpenVision(true);
          }
        
        const handleCloseVision = () => {
            setOpenVision(false); 
        }

    const currentYear = new Date().getFullYear()
    return (
        
        <Box mt={3}>
            <Stack spacing={2}
                alignItems="center">
                    <VisionDialog 
                    open={openVision}
                    onClose={handleCloseVision} 
                /> 

                <RegularButton
                    onClick={handleOpenVision}
                    text="Vision"/>

                <FaqDialog 
                    open={openFaq}
                    onClose={handleCloseFaq} 
                />
                <RegularButton onClick={handleOpenFaq} 
                    text="FAQ"/>  
               
                {articles.map((article, index) => (
                    <a 
                        href={article.href} 
                        key={index} 
                        target="_blank">


                        <RegularButton
                            component="a"
                            variant="outlined"
                            fullWidth
                            sx={{ marginBottom: 2,
                                color: '#FFFFFF' }}

                            fontFamily='Roboto Slab'
                            textAlign="center"

                            key={index}
                            text={article.title}
                        /> 
                    </a>
                ))}
                  

                
                 <Typography
                    fontFamily={"DiloWorld"}
                    fontSize='1rem'
                    fontWeight={"bold"}
                    color="#fff">
                        {currentYear} @ Metaversus.LLC
                </Typography>

            </Stack>
        
            
        </Box>
    );
};

export default DiscoverBullRush;
