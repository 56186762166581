import React, { useState } from 'react'
import { Routes, Route, Link } from "react-router-dom"


// MUI imports 
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Badge from '@mui/material/Badge'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { Tooltip } from '@mui/material'

import { Divider, Stack, Typography } from '@mui/material'


// Icons 
import SportsEsportsIcon from '@mui/icons-material/SportsEsports'
import PersonIcon from '@mui/icons-material/Person'
import DashboardIcon from '@mui/icons-material/Dashboard'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import SearchIcon from '@mui/icons-material/Search'
import NotificationsIcon from '@mui/icons-material/Notifications'
import WidgetsIcon from '@mui/icons-material/Widgets'
import StorefrontIcon from '@mui/icons-material/Storefront'
import LockClockIcon from '@mui/icons-material/LockClock';
import CheckIcon from '@mui/icons-material/Check';

import AssignmentIcon from '@mui/icons-material/Assignment'
import HomeLogo from '../logos/Bullrush_Logo.png'

// Local Imports 
import HomeScreen from '../pages/homescreen/HomeScreen'
import Dashboard from '../pages/dashboard/Dashboard'
import Play from '../pages/play/Play'
import Profile from '../pages/profile/profile/Profile'
import Settings from '../pages/profile/settings/Settings'
import MyTeam from '../pages/dashboard/MyTeam'
import Offers from '../pages/dashboard/Offers'
import Overview from '../pages/dashboard/Overview'
import Transactions from '../pages/dashboard/Transactions'

import DashboardNavigation from './DashboardNavigation'
import BRFAlert from './BRFAlert'
import BRFWalletInfo from './BRFWalletInfo'
import Background from '../components/Background'

import BRFWalletConnect from './BRFWalletConnect'
import { checkOwner, disconnectWallet, getConnectedWallet } from '../session/WalletSession'
import { getLocalAccessToken, getTermsAccepted } from '../session/backend_session/Backend'



// Utils Imports 
import { Search, SearchIconWrapper, StyledInputBase } from '../utils/BRFNavUtils'
import { useEffect } from 'react'
import { ConnectButton, RegularButton } from './BRFButtons'

//Terms and services dialog
import Legal from '../components/Legal.js'


// Backend Imports 

import { searchPlayers } from '../session/backend_session/Backend'
import SearchDisplay from './SearchDisplay'
import MobileSearchDisplay from './MobileSearchDisplay'
import Marketplace from '../pages/marketplace/Marketplace'
import ViewNotifications from './Notifications/ViewNotifications'
import LogoLoader from './LogoLoader'
import { Notify } from './Notify/Notify'
import BullRush_bg from '../images/bullrush_bg.jpg'

import AdminSubmit from './AdminSubmit.js'
import AdminTime from '../components/AdminTime.js'


const defaultSearchResult = {
    name: 'Nothing Found!',
}




const BullNav = ({ isAuthenticated }) => {



  /* BRFWalletConnect variables */
  const [walletStatus, setWalletStatus] = useState(false)

  /* Bull open icons */
  const [hovered, setHovered] = useState(false);
  const [submitOpen, setSubmitOpen] = useState(false)
  const [timeOpen, setTimeOpen] = useState(false)

  const handleHover = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  
  /* Bull Nav variables */
  const [anchorEl, setAnchorEl] = useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)

  /* BRF Alert variables */

  const [alertStatus, setAlertStatus] = useState(false)
  const [alertMessage, setAlertMessage] = useState(null)
  const [alertIndicator, setAlertIndicator] = useState(null)

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)


  /* Terms of agreement status */

  const [ isTermAccepted, setIsTermAccepted ] = useState(false)
  const [termsOpen, setTermsOpen] = useState(false);


  /* BRF Wallet Info Status */

  const [walletInfoStatus, setWalletInfoStatus] = useState(false) 

  /* Notification */
  
  const [ nofiticationDialogStatus, setNotificationDialogStatus ] = useState(false)

  /* Search Input variables */

  const [searchInputValue, setSearchInputValue] = useState('')
  const [searchResults, setSearchResults] = useState([defaultSearchResult])
  const [searchDisplayStatus, setSearchDisplayStatus] = useState(false)

  const [mobileSearchStatus, setMobileSearchStatus] = useState(false)

  /* Terms Handlers */
  const termsHandler = () => { 
    setWalletInfoStatus(true)
    setTermsOpen(false)
  }

  /* Menu Handlers */

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }


  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }


  /* BRFWalletConnect Handler */
  const handleWalletConnectDisplay = () => {
    setWalletStatus(true)  
  }
  
  const handleWalletConnectClosed = () => {
    setWalletStatus(false)
  }
/* Admin Function */

  const handleTimeOpen = () => {
    setTimeOpen(true)
  }
  const handleSubmitOpen = () => {
    setSubmitOpen(true)
  }


  /* BRFAlert  Handler */
  
  const handleAlertClosed = (event, reason) => {
    if(reason == 'clickaway'){
      return 
    }
    setAlertStatus(false) 
  }

  const displayAlert = (status, indicator, message) => {

      setAlertStatus(status)
      setAlertMessage(message)
      setAlertIndicator(indicator)
  }


  /* Notification Handler */

  const handleNotificationDialog = () => { 
    setNotificationDialogStatus(!nofiticationDialogStatus)
  }



  // BRF Wallet Info Handler 

  const handleWalletInfo = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }    
    setWalletInfoStatus(!walletInfoStatus)
  }

  //Legal Dialog
  const handleLegal = () => {
    if(!isTermAccepted) {
      setTermsOpen(true);
    } 
  }


  // search input handlers 
  const handleSearchInput = (event) => {
      setSearchInputValue(event.target.value)
  }

  const handleSearchDisplay = () => {
    setSearchDisplayStatus(false)
  }

  const handleMobileSearchDisplay = () => {
    setMobileSearchStatus(!mobileSearchStatus)
  }

  const handleEnterKeyPress = async (event) => {

    if(event.keyCode == 13){
      if(searchInputValue == ''){
        
        displayAlert(true, 'info', 'You need letters buddy!')
        return 
      }
      // submit to api 
      displayAlert(true,'info', 'Searching.....')
      return await searchPlayers(searchInputValue)
              .then((res) => {
                displayAlert(true, 'success', 'Loading Results')
                setSearchDisplayStatus(true)
                setSearchResults(res.data)
              }).catch(() => {
                displayAlert(true, 'error', 'Error While Fetching Results')
              })

    }
  }
    


  /* Wallet Session Handlers */
  const handleWalletDisconnect = () => {
    displayAlert(true, 'info', `Wallet Disconnected`)
    return disconnectWallet()
  }

  


  /* Render Mobile Menu */
  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu

        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
    >
      <MenuItem
        onClick={handleNotificationDialog}>

        <IconButton  
            size="large" 
            aria-label="new notifications" 
            color="inherit">
            <Badge badgeContent={0} color="error">
                <NotificationsIcon />
             </Badge>
        </IconButton>

        <p>Notifications</p>
      </MenuItem>


      <MenuItem component={Link} to={'/'}>

        <IconButton 
          size="large" 
          aria-label="dashboard" 
          color="inherit">
            <DashboardIcon />
        </IconButton>
        
        <p>Dashboard</p>
        
      </MenuItem>


      <MenuItem component={Link} to={"/play"}>

        <IconButton 
          size="large"
          aria-label="show 17 new notifications"
          color="inherit">
            <SportsEsportsIcon />
        </IconButton>

        <p>Game</p>
      </MenuItem>



      <MenuItem component={Link} to={'/profile'}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <PersonIcon />
        </IconButton>
        <p>Profile</p>
      </MenuItem>


      <MenuItem component={Link} to={'/marketplace'}>
        <IconButton
          size="large"
          aria-label="marketplace"

          color="inherit"
        >
          <StorefrontIcon />
        </IconButton>
        <p>MarketPlace</p>
      </MenuItem>



      <MenuItem 
        onClick={handleMobileSearchDisplay}>
        <IconButton
          size="large"
          aria-label="search"
          aria-controls="search"
          color="inherit"
        >
          <SearchIcon />
        </IconButton>
        <p>Search</p>
      </MenuItem>



    </Menu>
  )


  useEffect(() => {

  
    const _loadApplication = () => { 
      
      // check authentication 
      if(!isAuthenticated){ 
        return 
      }
      // alerts 
      displayAlert(true, 'success', `Connected with :  ${getConnectedWallet()}`)
    }

    const checkTermsAccepted = async () => { 
      if(isAuthenticated){
        if(open){
          await getTermsAccepted()
            .then((res) => { 
              setIsTermAccepted(res.data)
            })
            .catch(() => { 
              console.error('Error With Terms Status')
            })
        }
      }
    }
  

    _loadApplication() 
    checkTermsAccepted()


  }, [isAuthenticated])




  console.log('TERM STATUS', isTermAccepted)
  /* If Wallet is not connected */

  if(!isAuthenticated){

    return (
        <Box>            

            <Box
                mt={{xs: 0, sm: 0, md: 0}}
                justifyContent="center"
                alignItems="center"
                display="flex"
                width="100%"
                >
                <AppBar 
                    position="fixed" 
                    sx={{ 
                      backgroundColor: '#0f111f',
                      top: 0
                    }}>


                    <Toolbar >
                        <Stack 
                            mt={2} 
                            direction="column"
                            spacing={2}
                            width="100%"
                            display="flex"                            
                            maxWidth="100%"
                            margin={'20px'}>
                        <Stack
                            direction="row"
                            spacing={{xs: 2, md: -6}}
                            alignItems="center"
                            maxWidth="100%">

                              
                          <Box sx={{ flexGrow: 0.1 }} />


                            {/* CHange with BRF LOGO */}
                          <Box>
                            <Link to="/">
                                  <img  
                                    src={HomeLogo}
                                    width="auto"
                                    height="75px" 
                                    alt="Bullrush Logo"
                                    sx={{
                                      position: 'absolute',
                                      left: '50%',
                                      transform: 'translateX(-50%)'
                                    }}
                                  />
                            </Link>
                          </Box>
                          <Box sx={{ flexGrow: 0.8}}/> 

                          


                            <Box sx={{ flexGrow: 0.8}}/> 

                            <ConnectButton
                              text={"connect wallet"} 
                              onClick={handleWalletConnectDisplay}/>

                        </Stack>
                        </Stack>

                        
                        
                    </Toolbar>
                </AppBar>

            </Box>


            <Background>
              <div style={{
              position: 'static',
              minHeight: '100vh',
              width: '100vw',
              top: 0,
              left: 0, 
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0,0,0,0.9)',
              backgroundAttachment: 'fixed'
              }}>
                
              <Box 
                  alignItems="center"
                  justifyContent="center"
                  display="flex"
                  width="100%"
                  sx={{
                    zIndex:1, 
                    backgroundImage: BullRush_bg
                  }}>

                    <HomeScreen/> 
                    <BRFWalletConnect status={walletStatus} handler={handleWalletConnectClosed}/>
                    <BRFAlert 
                          status={alertStatus}
                          handler={handleAlertClosed}
                          indicator={alertIndicator}
                          message={alertMessage}/>


              </Box>
          </div>
          </Background>


      </Box>
    )
  }




  return (

    
    <Box>

        <Box
            justifyContent="center"
            alignItems="center"
            width="100%"
            maxWidth="100%"
            mt={1} >

            <AppBar 
                position="fixed" 
                sx={{
                    top: 0,
                    backgroundColor: '#0f111f',
                    }}>
                <Toolbar>


                    <Stack 
                        mt={5}
                        direction="column"
                        spacing={2}
                        width="100%"
                        maxWidth="100%">

                        <Stack 
                            direction="row"
                            spacing={0}
                            maxWidth="100%">


                            <Box sx={{ flexGrow: 0.1 }} />


                            <Box>
                                {/* BRF LOGO */}

                                <Link to="/">
                                  <img  
                                    src={HomeLogo}
                                    width="77px"
                                    height="55px" 
                                    alt="Bullrush Logo"
                                    sx={{
                                      position: 'absolute',
                                      top: '-20px', /* adjust as needed */
                                      left: '50%', 
                                      transform: 'translateX(-50%)',
                                      transition: 'top 0.2s ease',
                                    }}
                                  />
                                </Link>

                            </Box>



                            <Box sx={{ flexGrow: 0.8 }} />



                            <Box sx={{ display: { xs: 'none', md: 'flex' }}}>

                              <Stack 
                                direction="row"
                                spacing={1}>


                               
                                    <Search 
                                      >

                                      <SearchIconWrapper>

                                          <SearchIcon />
                                      </SearchIconWrapper>

                                      <StyledInputBase
                                          placeholder="Lookup Player ID, Owner Address, Team Name"
                                          inputProps={{ 'aria-label': 'search' }}
                                          onChange={handleSearchInput}
                                          value={searchInputValue}
                                          onKeyDown={handleEnterKeyPress}
                                          
                                          
                                          />

                                    </Search>


                                  <Notify 
                                    onClick={handleNotificationDialog}
                                  />
                                 
                                {checkOwner() &&
                                <IconButton
                                    sx={{ 
                                        backgroundColor: '#8B0000',
                                         ":hover": {
                                            backgroundColor: '#FF0000'
                                        }
                                      }}
                                    size="large"
                                    edge="end"
                                    aria-label="Clock"
                                    color="inherit"
                                    onClick={handleSubmitOpen}
                                    >
                                    <CheckIcon />
                                </IconButton>
                                }
                                  
                                {checkOwner() &&
                                  <IconButton
                                      sx={{ 
                                          backgroundColor: '#8B0000',
                                          ":hover": {
                                              backgroundColor: '#FF0000'
                                          }
                                        }}
                                      size="large"
                                      edge="end"
                                      aria-label="Clock"
                                      color="inherit"
                                      onClick={handleTimeOpen}
                                      >
                                      <LockClockIcon />
                                  </IconButton>
                                }
                                
                              <Tooltip title="Dashboard">
                                <IconButton component={Link} to={"/"}
                                    sx={{ 
                                        backgroundColor: '#37363b',
                                         ":hover": {
                                            backgroundColor: '#9D9CA1'
                                        }
                                      }} 
                                    size="large"
                                    aria-label="dashboard icon"
                                    color="inherit">
                                    <DashboardIcon />
                                </IconButton>
                                </Tooltip>


                                <Tooltip title="Play">

                                <IconButton component={Link} to={"/play"}
                                    sx={{ 
                                        backgroundColor: '#37363b',
                                         ":hover": {
                                            backgroundColor: '#9D9CA1'
                                        }
                                      }}
                                    size="large"
                                    aria-label="play icon"
                                    color="inherit">
                                    <SportsEsportsIcon />
                                </IconButton>
                                </Tooltip>


                              
                                {/*
                                  <IconButton component={Link} to={"/profile"}
                                      sx={{ 
                                          backgroundColor: '#37363b',
                                          ":hover": {
                                              backgroundColor: '#9D9CA1'
                                          }
                                        }}
                                      size="large"
                                      edge="end"
                                      aria-label="account of current user"
                                      color="inherit"
                                      >
                                      <AccountCircle />
                                  </IconButton>
                                */}

                              <Tooltip title="Marketplace">

                                <IconButton component={Link} to={"/marketplace"}
                                    sx={{ 
                                        backgroundColor: '#37363b',
                                         ":hover": {
                                            backgroundColor: '#9D9CA1'
                                        }
                                      }}
                                    size="large"
                                    edge="end"
                                    aria-label="Leasing Marketplace"
                                    color="inherit"
                                    >
                                    <StorefrontIcon />
                                </IconButton>
                              </Tooltip>

                            
                              <Tooltip title="Mint">
                                {!isTermAccepted ?
                                  <IconButton
                                      sx={{ 
                                          backgroundColor: '#003153', color: '#fff',
                                          ":hover": {
                                              backgroundColor: '#194564'
                                          }
                                        }} 
                                      size="large"
                                      aria-label="terms agreement"
                                      color="inherit"
                                      onClick={handleLegal}>
                                      <AssignmentIcon/>
                                  </IconButton>
                                :
                                  <IconButton
                                      sx={{ 
                                          backgroundColor: '#003153', color: '#fff',
                                          ":hover": {
                                              backgroundColor: '#194564'
                                          }
                                        }} 
                                      size="large"
                                      aria-label="wallet icon"
                                      color="inherit"
                                      onClick={handleWalletInfo}>
                                      <AccountBalanceWalletIcon />
                                  </IconButton>
                                }
                              </Tooltip>


                              </Stack>
                            </Box>
                            

                            <Box sx={{ display: { xs: 'flex', md: 'none' }}}>
                                <IconButton
                                  size="large"
                                  aria-label="show more"
                                  aria-controls={mobileMenuId}
                                  aria-haspopup="true"
                                  onClick={handleMobileMenuOpen}
                                  color="inherit"
                                  >
                                  <WidgetsIcon />
                                </IconButton>
                            </Box>


                        </Stack> 

                        <Divider sx={{backgroundColor: 'white'}}/> 

                    </Stack>


                </Toolbar>


            </AppBar>


            <Box 
                mt={25}
                alignItems="center"
                justifyContent="center"
                display="flex"
                maxWidth="100%"
                
                >

                    <Routes>
                        <Route  exact path="/" element={<Dashboard/>}/>
                        <Route path="/play" element={<Play/>}/>
                        <Route path="/profile" element={<Profile/>}/>
                        <Route path="/settings" element={<Settings/>}/>
                        <Route path="/myteam" element={<MyTeam/>}/>
                        <Route path="/offers" element={<Offers/>}/>
                        <Route path="/overview" element={<Overview/>}/>
                       {/* <Route path="/sales" element={<Sales/>}/> */}
                        <Route path="/transactions" element={<Transactions/>}/>
                        <Route path="/marketplace" element={<Marketplace/>}/>

                    </Routes>
            </Box>

            {renderMobileMenu}
      </Box> 

    

        

        <DashboardNavigation handleWalletDisconnect={handleWalletDisconnect}/>
        <BRFAlert 
           status={alertStatus}
           handler={handleAlertClosed}
           indicator={alertIndicator}
           message={alertMessage}/>
         <AdminSubmit
              open={submitOpen}
              onClose={() => setSubmitOpen(false)}
          />

        <AdminTime
            open={timeOpen}
            onClose={() => setTimeOpen(false)}
          />

        {!isTermAccepted && 
          <Legal
              open={termsOpen}
              termsHandler={termsHandler}
              onClose={() => setTermsOpen(false)}
            />
        }

        <BRFWalletInfo status={walletInfoStatus} handler={handleWalletInfo}/>
        <SearchDisplay status={searchDisplayStatus} handler={handleSearchDisplay} searchList={searchResults}/>
        <MobileSearchDisplay status={mobileSearchStatus} handler={handleMobileSearchDisplay}/>
        <ViewNotifications status={nofiticationDialogStatus} handler={handleNotificationDialog}/>

    </Box>
  )
}


export default BullNav 