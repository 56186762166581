
import { ethers } from 'ethers' 
import axios from 'axios'


import { checkExistingUsers, setAuthTokens } from '../session/backend_session/SessionHandler'
import { createUser, signin } from '../session/backend_session/Backend'
import Torus from "@toruslabs/torus-embed";


import CONTRACT_FILE from '../utils/DynamicNfts.json'
import { connectSocket } from './NotifySocket'
export const PROVIDER = process.env.REACT_APP_PROVIDER_URL
export const OWNER_ADDRESS = process.env.REACT_APP_OWNER_ADDRESS

// environment for webapp 
//const ENV = process.env.REACT_APP_ENV


//const KEY = process.env.REACT_APP_PROVIDER_KEY 
const default_auth_token = process.env.REACT_APP_TOKEN 
const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS 


console.log('Contract Address: ', CONTRACT_ADDRESS)

const Web3 = require('web3')
const coreProvider = new Web3(PROVIDER)
const { ethereum } = window 
const CONTRACT_ABI = CONTRACT_FILE.abi
const torus = new Torus();


const mintWallets = ['MetaMask', 'Coinbase', 'WalletConnect', 'Torus']

const getWeb3Provider = () => {
    if(coreProvider == null){
        console.log('Core Provider is null')
        return 
    }
    return coreProvider
}

const activateSession = (address, chain, provider) => {

    localStorage.setItem('BRFConnectedWallet', coreProvider.utils.toChecksumAddress(address))
    localStorage.setItem('BRFConnectedChainID', chain)
    localStorage.setItem('BRFConnectedProvider', provider) 

}

const handleBRFISConnected = ( status ) => {
    localStorage.setItem('BRFISConnected', status)  
}

const getConnectedWallet = () => {
    
    const account = localStorage.getItem('BRFConnectedWallet')
    return account 

}

const getConnectedChainID = () => {

    const chainID = localStorage.getItem('BRFConnectedChainID')
    return chainID 

}

const getConnectedProvider = () => {
    
    const provider = localStorage.getItem('BRFConnectedProvider')
    return provider 
}

const getConnectionStatus = () => {
    const isConnected = localStorage.getItem('BRFISConnected')
    return isConnected
}

const getCoinBaseAccount = () => {
    const account = localStorage.getItem('-walletlink:https://www.walletlink.org:Addresses')
    return account 
}

const getCoinBaseChainID = () => {
    const chainID = localStorage.getItem('-walletlink:https://www.walletlink.org:DefaultChainId')
    return chainID 
}


const disconnectWallet = () => {
    localStorage.clear()
    sessionStorage.clear() 
}


/* 
    Purpose: 
        Log or Create User to the backend
            if there's an existing user
                log user into backend
            if user is new 
                create user account and log user into backend
    
    Args: None 
*/
const handleExistingOrNewUser = async () => {

    const existingUsers = await checkExistingUsers()
    let  userAddress = null 
    for( let  address = 0; address < existingUsers.length; address++){
        if(getConnectedWallet() == existingUsers[address]){
            
            userAddress = existingUsers[address]
            //console.log('Picked up address: ', userAddress) 
            const auth_data = await signin({ address: userAddress, password: default_auth_token})
            const { access, refresh }  = auth_data.data 
            setAuthTokens(access, refresh) 
            handleBRFISConnected(true)
            return 
            
        }
        
    }

    //console.log('Connected Wallet: ', getConnectedWallet())
    await createUser({address: getConnectedWallet(), password: default_auth_token}).catch(() => { return }) // add to BRFAlert
    const sign_data = await signin({ address: getConnectedWallet(), password: default_auth_token}).catch(() => { return }) // add to BRFAlert 
    const { access, refresh } = sign_data.data 
    setAuthTokens(access, refresh) 
    handleBRFISConnected(true)


}


const estimateGas = async () => { 
    let maxFeePerGas = ethers.BigNumber.from(40000000000) // fallback to 40 gwei
    let maxPriorityFeePerGas = ethers.BigNumber.from(40000000000) // fallback to 40 gwei
    try {
        const { data } = await axios({
            method: 'get',
            url: 'https://gasstation-testnet.polygon.technology/v2',
        })
        maxFeePerGas = ethers.utils.parseUnits(
            Math.ceil(data.fast.maxFee) + '', 
            'gwei'
        )
        maxPriorityFeePerGas = ethers.utils.parseUnits(
            Math.ceil(data.fast.maxPriorityFee) + '',
            'gwei'
        )

        return { maxFeePerGas, maxPriorityFeePerGas}
    } catch {
        // ignore
    }
}




const checkWalletBalance = async () => { 

    const data = await coreProvider.eth.getBalance(getConnectedWallet())
    const eth_balance = await coreProvider.utils.fromWei(data, 'ether')
    console.log('From Wallet Session', eth_balance)
    return eth_balance

}


export const checkOwner = () => { 
    if(getConnectedWallet() == OWNER_ADDRESS){
        return true 
    }
    return false 
}



export {

    getWeb3Provider,
    activateSession, 
    getConnectedChainID,
    getConnectedProvider,
    getConnectedWallet,
    getConnectionStatus,
    getCoinBaseAccount,
    getCoinBaseChainID,
    disconnectWallet,
    handleBRFISConnected,
    handleExistingOrNewUser,
    checkWalletBalance,
    estimateGas,
}




