import React, { useState }from 'react'
import Dialog from '@mui/material/Dialog'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Stack from '@mui/material/Stack' 
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'

import  { BRFTransition } from '../../utils/BRFTransition'
import { CardMedia, Chip, DialogContent } from '@mui/material'

import BullRush_bg from '../../images/bullrush_bg.jpg'


import { RegularButton } from '../BRFButtons'
import BRFPanel from '../BRFPanel'
import BRFCardTraits from './BRFCardTraits'
import LeasingPanel from '../leasing/LeasingPanel'
import { getConnectedWallet, getWeb3Provider } from '../../session/WalletSession'

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;



const DetailLabel = ({label}) =>{

  return (
    
        
        <Chip
          sx={{
              fontWeight: 'bolder', fontSize: {xs: '1rem', md:'1.2rem'}, color: '#fff'
          }}
          variant="outlined"
          fontFamily="monospace"
          size="sm"
          label={`${label} : `}/>
       
  )
}

const DetailValue = ({value}) => {
  return (

      <Chip 
        sx={{
            fontWeight: 'bolder', fontSize: {xs: '1rem',md: '1rem'}, color: '#fff'
        }}
        variant="outlined"
        fontFamily="monospace"
        size="sm"
        maxLength={10}
        label={value}/>

  )
}

const WalletAddressValue = ({value}) => {
  return (
    <div style={{overflow: "hidden", textOverflow: "ellipsis", width: '10rem'}}> 

      <Chip 
        sx={{
            fontWeight: 'bolder', fontSize: {xs: '1rem', md:'1rem'}, color: '#fff'
        }}
        variant="outlined"
        fontFamily="monospace"
        size="sm"
        maxLength={10}
        label={value}/>
      </div>


  )
}


const ContractAddressValue = ({value}) => {
  return (
    <div style={{overflow: "hidden", textOverflow: "ellipsis", width: '10rem'}}> 

      <Chip 
        sx={{
            fontWeight: 'bolder', fontSize: {xs: '1rem', md:'1rem'}, color: '#fff'
        }}
        variant="outlined"
        fontFamily="monospace"
        size="sm"
        maxLength={10}
        label={value}/>
      </div>


  )
}


const DetailLayout = ({name, contract, owner, symbol, tokenId, block_number, block_number_minted}) => {

  return (
    <Stack 
      direction="row"
      spacing={{xs: 0, md: 2}}
      justifyContent="center"
      alignItems="center"
      display="flex">

        <Stack 
          direction="column"
          spacing={2}>

            <DetailLabel label="Name"/>
            <DetailLabel label="Contract"/>
            <DetailLabel label="Owner"/>
            <DetailLabel label="Symbol"/>
            <DetailLabel label="Token ID"/>
            <DetailLabel label="Block # "/>
            <DetailLabel label="Block # Minted"/>

        </Stack>

        <Stack 
          direction="column"
          spacing={2}>

            <DetailValue value={name}/>
            <ContractAddressValue value={contract}/>
            <WalletAddressValue value={owner}/>
            <DetailValue value={symbol}/>
            <DetailValue value={tokenId}/>
            <DetailValue value={block_number}/>
            <DetailValue value={block_number_minted}/>


        </Stack>

    </Stack>
  )
}



const BRFCardDetails = ({status, handler, details}) => {

  //console.log(details.card_info.card_traits)


  const [openTraits, setOpenTraits] = useState(false) 
  const [leasingDialogStatus, setLeasingDialogStatus] = useState(false)




  const handleLeasingDialog = () => {
    setLeasingDialogStatus(!leasingDialogStatus)
  }

  const handleTraitsDialog = () => {
    setOpenTraits(!openTraits)
  }

  const isOwner = () => {

    let owner_of = null 
    if(details.owner_of){
        owner_of = getWeb3Provider().utils.toChecksumAddress(details.owner_of)
      if(owner_of != getConnectedWallet()){
        return false
      }
      return true
    }

    return false 

  }


  /* 
    Purpose: 
      Open Token of Contract on Etherscan
  */
  const handleTokenOnEtherscan = (tokenId) => {
    const url = `https://mumbai.polygonscan.com/token/${contractAddress}?a=${details.tokenId}`;
    window.open(url, '_blank'); 
  } 

  //console.log('Traits Dialog Status', openTraits)
  console.log(`Lease Details: ${details.brfcard_leasing.is_leased}`)
  return (
     
      <Dialog
        fullScreen
        open={status}
        onClose={handler}
        TransitionComponent={BRFTransition}>


        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>

            <IconButton
              edge="start"
              color="inherit"
              onClick={handler}
              aria-label="close">

              <CloseIcon />

            </IconButton>


            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" fontFamily="AZN">
                {details.name}
            </Typography>

            {!isOwner() && !details.brfcard_leasing.is_leased &&
                <RegularButton text="lease" onClick={handleLeasingDialog}/>
            
            }


          </Toolbar>
        </AppBar>


        <DialogContent 
          sx={{ backgroundColor: '#0f111f',  maxWidth: '100%'}}>

          <Stack 
            mt={5}
            direction="column"
            spacing={2}
            justifyContent="center"
            alignItems="center">

            


            <Stack 
                mt={2}
                direction="column"
                spacing={3}
                justifyContent="center"
                alignItems="center"
                >

                <CardMedia

                    sx={{
                      borderRadius: '8px',
                      boxShadow: '0 4px 10px #aaa',                      
                      width: { xs: '90%', sm: '70%', md: '50%', lg: '30%' }, 
                      backgroundSize: '20px 20px',
                      backgroundRepeat: 'round',
                      p: '20px',
                      backgroundImage: `url(${BullRush_bg})`,


                    }}
                    component="img"
                    src={details.image}/> 


            </Stack>



            <BRFPanel>

              <Stack 
                mt={10}
                direction="column"
                spacing={3}
                justifyContent="center"
                alignItems="center"
                display="flex">

                  
                  <Stack 
                    direction={{xs: 'column', sm: 'column', md: 'column'}}
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    spacing={5}
                    >

                   

                    <Stack 
                      direction="column"
                      spacing={4}
                      justifyContent="center"
                      alignItems="center">

                      <DetailLayout 
                        name={details.name}
                        contract={details.contract_address} 
                        tokenId={details.tokenId}
                        owner={details.owner_of}
                        symbol={details.symbol}
                        block_number={details.block_number}
                        block_number_minted={details.block_number_minted}/>

                    <Stack 
                      direction="row"
                      spacing={2}>
                          <RegularButton 
                            text={"view traits"}  
                            onClick={handleTraitsDialog}/>
                          <RegularButton text={"Etherscan"} onClick={handleTokenOnEtherscan}/>
                    </Stack>

                      
                  </Stack>

                  
                  </Stack>

                  

              </Stack>

            </BRFPanel>

          </Stack>

        </DialogContent>
        
        <BRFCardTraits status={openTraits} traits={details.card_info.card_traits} handler={handleTraitsDialog}/>
        {!isOwner() && 
        <LeasingPanel status={leasingDialogStatus} handler={handleLeasingDialog} token={details}/>                 
        }
        </Dialog>
  )
}

export default BRFCardDetails
