import React, { useEffect, useState } from 'react' 

import { Box, Stack, Grid } from '@mui/material'

import TeamPanel from '../../components/TeamPanel'
import BullCard from '../../components/BRFCard/BullCard'
import BRFTypography from '../../components/BRFTypography'
import { MintButton } from '../../components/BRFButtons'

import TeamControls from '../../components/TeamControls'
import ReservedSquad from '../../components/ReservedSquad'
import BRFCardActions from '../../components/BRFCard/BRFCardActions'

import { getActiveCards, getListedMarketCards, pushToMarketByID } from '../../session/backend_session/Backend'
import { pushToReserves } from '../../session/backend_session/Backend'

import MintDialog from '../../components/mintComponents/MintDialog';





import BRFPanel from '../../components/BRFPanel'
import ListedCards from '../../components/Marketplace/ListedCards'

/* 
    - Get All BRF Cards belong to request.user 
    - Map it to array
    - assign tokenid to each
*/


const MyTeam = () => {

    const [bullCards, setBullCards] = useState([])
    const [listedCards, setListedCards] = useState([])
    
    
    const [reservedPanelStatus, setReservedPanelStatus] = useState(false)
    const [listedPanelStatus, setListedPanelStatus] = useState(false) 


    const [alertStatus, setAlertStatus] = useState(false)
    const [alertIndicator, setAlertIndicator] = useState()
    const [alertMessage, setAlertMessage] = useState()


    const [mintDialogStatus, setMintDialogStatus] = useState(false);  

    const handleMintDialog = () => {
        setMintDialogStatus(!mintDialogStatus);
    };


    const handleAlert = (reason) => {
        if(reason == 'clickaway'){
            return 
        }

        setAlertStatus(true)
    }

    const displayAlert = (status, indicator, message) => {

        setAlertStatus(status)
        setAlertMessage(message)
        setAlertIndicator(indicator)
    }


    /* reserved panel handler */

    const handleReservedPanel = async () => {
        setReservedPanelStatus(!reservedPanelStatus)
        await refreshActiveSquad()
    }

    const handleListedPanel = async () => { 
        setListedPanelStatus(!listedPanelStatus) 
        await displayListedCards() 
        await refreshActiveSquad() 
    }

    const moveToReserves = async (tokenId) => {

        await pushToReserves(tokenId)
                .then(() => {
                    console.log('Pushing to Reserved Squad')
                    refreshActiveSquad() 
                })
                .catch((error) => {
                    console.log(error)
                })
                console.log('Card Data Refreshed')

    }

    const moveToMarket = async ( market_id ) => { 
        await pushToMarketByID(market_id)
                .then(() => { 
                    console.log('Pushing to Market')
                    refreshActiveSquad() 
                })
                .catch((error) => { 
                    console.error(error)
                })
    }

    const displayListedCards = async () => { 

        const listed_data = await getListedMarketCards() 
        setListedCards(listed_data.data)

    }


    const refreshActiveSquad = async () => {

        const squad_data = [] 

        const data = await getActiveCards()
        if(data.data){

            const active = data.data.active 
            const leased = data.data.leased 

            active.map((token) => { 
                squad_data.push(token)
            })

            leased.map((token) => { 
                squad_data.push(token)
            })

            setBullCards(squad_data)
            console.log('Refreshing.............')
        }

    }




 
    useEffect(() => {

        const mountActiveCards = async () => {
            
            const squad_data = [] 

            const data = await getActiveCards()
            if(data.data){

                const active = data.data.active 
                const leased = data.data.leased 

                active.map((token) => { 
                    squad_data.push(token)
                })

                leased.map((token) => { 
                    squad_data.push(token)
                })

                setBullCards(squad_data)
                console.log('Refreshing.............')
            }

        }


   
        mountActiveCards()

    }, [])

    //console.log('Cards: ', bullCards)
    //console.log('Players Count: ', numberOfCards)
    return (
        <Box 
            mt={{xs: -10, sm: -10, md: -10, lg: -8}}
            maxWidth="100%">

           <Stack 
                maxWidth="100%"
                direction="column"
                spacing={{xs: 1, md: 2}}
                justifyContent="center"
                alignItems="center">

                <TeamControls 
                    reservedHandler={handleReservedPanel} 
                    refreshHandler={refreshActiveSquad}
                    listedHandler={handleListedPanel}
                    />

                {bullCards.length == 0 ? (

                   <BRFPanel>
                    <Stack 
                        mt={10}
                        direction="column"
                        spacing={2}
                        justifyContent="center"
                        alignItems="center">
                            <BRFTypography text={"No Bull Cards Available"}/>
                            <MintButton 
                                text="Mint"
                                onClick={handleMintDialog}/>
                                
                            <MintDialog 
                                status={mintDialogStatus}
                                handler={handleMintDialog} 
                            />
                    </Stack>
                   </BRFPanel>

                ): (
                     <TeamPanel>
                        
                        <Grid 
                            sx={{ mb: 10, maxWidth: "100%" }}
                            container 
                            spacing={{ xs: 2, md: 1, lg: 2}}
                            columns={{ xs: 1, sm: 0, md: 12 }}
                            display="flex"
                            justifyContent={{xs: 'center', md: 'space-evenly'}}
                            alignItems="center">

                        {bullCards.map(( card, key) => (
                            <Grid 
                                item 
                                xs={2}
                                sm={4}
                                md={3} 
                                key={key}
                                spacing={2}>

                                    <BullCard 
                                        data_handler={card}
                                        from={"team"}/> 
                                   

                                        <BRFCardActions 
                                            tokenId={card.tokenId} 
                                            moveToReserves={() => { moveToReserves(card.tokenId)}}
                                            moveToMarket={() => { moveToMarket(card.market_details.market_id)}} 
                                            squadStatus={card.squad_status}
                                            refreshContainer={refreshActiveSquad}                        
                                        />

                            </Grid>
                        ))}
                        
                        </Grid>
                    </TeamPanel>


                )}
                

            </Stack>

            <ReservedSquad 
                status={reservedPanelStatus} 
                handler={handleReservedPanel}/>

            <ListedCards
                status={listedPanelStatus}
                handler={handleListedPanel}
                dataContainer={listedCards}
                refreshContainer={displayListedCards}/>
        </Box>
    )
}

export default MyTeam 