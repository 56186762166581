
import React, { useState, useEffect } from 'react'

import { Dialog, DialogContent, AppBar , IconButton, Toolbar, Stack, Typography, CircularProgress, Box, Button } from '@mui/material'
import { BRFTransition } from '../../utils/BRFTransition'
import BRFTypography from '../BRFTypography'
import CloseIcon from '@mui/icons-material/Close'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import { MintButton, MintWhiteListButton, RegularButton } from '../BRFButtons'
import { checkWalletBalance, desktopMint, getConnectedProvider, getConnectedWallet, getWeb3Provider } from '../../session/WalletSession'
import MintInfo from './MintInfo'
import { isMobile } from '../../utils/MobileUtils'
import { getRecentlyMinted } from '../../session/backend_session/Backend'
import BullCard from '../../components/BRFCard/BullCard'
import { WSController, coinBaseController, metamaskController, torusController } from '../../BullApp'
import { BigNumber } from 'ethers'
import { withStyles } from '@mui/styles'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import BRFPanel from '../BRFPanel'


const CONTRACT_MINT_PRICE = process.env.REACT_APP_CONTRACT_MINT_PRICE
const CONTRACT_MINT_BUNDLE_PRICE = process.env.REACT_APP_CONTRACT_MINT_BUNDLE_PRICE

const MintDialog = ({ status, handler}) => {


    const [ provider, setProvider ] = useState(getWeb3Provider())
    const [ mintMessage, setMintMessage ] = useState("")
    const [ mintQuantity, setMintQuantity ] = useState(1)
    const [ mintPrice, setMintPrice ] = useState(CONTRACT_MINT_PRICE)
    const [ readableMintPrice, setReadableMintPrice ] = useState("")

    const [ mintTriggered, setMintTriggered ] = useState(false)
    const [ mintStatus, setMintStatus ] = useState({ minted: false, message: '', fullMessage: '', action: false})
    const [ mintedCards, updateMintedCards ] = useState([])
    const [fetchingMintedCard, setFetchingMintedCard] = useState(false)

    const [ toggleMintOptionView, setToggleMintOptionView ] = useState(false)

    const [ allowedIncrement, setAllowedIncrement ] = useState(false)
    const [ allowedDecrement, setAllowedDecrement ] = useState(false)

    const [ mintBundleStatus, setMintBundleStatus ] = useState(false)
    const [ freeMintClaimed, setFreeMintClaimed ] = useState(false)
    const [ freeMintBundleClaimed, setFreeMintBundleClaimed ] = useState(false)
    const [ isAllowListClaimed, setALlowListClaimed ] = useState(false)



    const incrementMintQuantity = () => {
        if(mintQuantity >= 5){
            setAllowedIncrement(false)
            return 
        }
        setMintQuantity(prev => prev += 1)
    }

    const decrementMintQuantity = () => {
        if(mintQuantity <= 1){
            setAllowedDecrement(false)
            return 
        }
        setMintQuantity(prev => prev -= 1)
    }

    const handleToggleMintOptionView = () => {
        setToggleMintOptionView(!toggleMintOptionView)
    }

    const handleMintInfo = () => { 
        setMintStatus({...mintStatus, action: false})
    }

    const convertMatic = async ( matic ) => { 
        let data =  await provider.utils.toWei(String(matic), 'ether')
        return data
    }

    const converMaticToPrice = async ( matic ) => { 
        let data = await provider.utils.fromWei(String(matic), 'ether')
        return data 
    }

    const checkFreeMintBundleClaimed = async () => { 
        const providerId = getConnectedProvider() 
        if(providerId == "MetaMask"){
            const status = await metamaskController.isFreeBundleMintClaimed()
            return status 
        }
        if(providerId == "Torus"){
            const status = await torusController.isFreeBundleMintClaimed()
            return status 
        }
        if(providerId == "CoinBase"){
            const status = await coinBaseController.isFreeBundleMintClaimed()
            return status 
        }
        if(providerId == "WalletConnect"){
            const status = await WSController.isFreeBundleMintClaimed()
            return status 
        }
    }

    const checkAllowListClaimed = async () => { 
        const providerId = getConnectedProvider() 
        if(providerId == "MetaMask"){
            const status = await metamaskController.isAllowListClaimed()
            return status 
        }
        if(providerId == "Torus"){
            const status = await torusController.isAllowListClaimed
            return status 
        }
        if(providerId == "CoinBase"){
            const status = await coinBaseController.isAllowListClaimed
            return status 
        }
        if(providerId == "WalletConnect"){
            const status = await WSController.isAllowListClaimed
            return status 
        }
    }

    const checkFreeMintClaimed = async () => { 
        const providerId = getConnectedProvider() 
        if(providerId == "MetaMask"){
            const status = await metamaskController.isFreeMintClaimed()
            return status 
        }
        if(providerId == "Torus"){
            const status = await torusController.isFreeMintClaimed()
            return status 
        }
        if(providerId == "CoinBase"){
            const status = await coinBaseController.isFreeMintClaimed()
            return status 
        }
        if(providerId == "WalletConnect"){
            const status = await WSController.isFreeMintClaimed()
            return status 
        }
    }

    const checkBundleMintStatus = async () => { 
        const providerId = getConnectedProvider() 
        if(providerId == "MetaMask"){
            const status = await metamaskController.bundleSaleStatus()
            return status 
        }
        if(providerId == "Torus"){
            const status = await torusController.bundleSaleStatus()
            return status 
        }
        if(providerId == "CoinBase"){
            const status = await coinBaseController.bundleSaleStatus()
            return status 
        }
        if(providerId == "WalletConnect"){
            const status = await WSController.bundleSaleStatus()
            return status 
        }
    }


    const initializeMint = async () => {

        setMintTriggered(true)
        console.log('Wallet', getConnectedWallet())
        // check if user has sufficient funds
        let balance = await checkWalletBalance()
        if(balance < provider.utils.fromWei(mintPrice)){
            setMintMessage("Insufficient Funds!")
            handleMintInfo()
            return 
        }

        const providerId = getConnectedProvider()
        if(providerId == "Torus"){
            const mint = await torusController.handleMint(mintPrice, mintQuantity)
            setMintStatus({ minted: mint.minted, message: mint.message, action: mint.action})
            setMintTriggered(false)
            return 
        }
        if(providerId == "MetaMask"){
            const mint = await metamaskController.handleMint(mintPrice, mintQuantity)
            setMintStatus({ minted: mint.minted, message: mint.message, action: mint.action, fullMessage: mint.fullMessage})
            setMintTriggered(false)
            return 
        }

        
        if(providerId == "CoinBase"){
            const mint = await coinBaseController.handleMint(mintPrice, mintQuantity)
            setMintStatus({ minted: mint.minted, message: mint.message, action: mint.action})
            setMintTriggered(false)
            return 
        }

        if(providerId == "WalletConnect"){
            const mint = await WSController.handleMint(mintPrice, mintQuantity)
            setMintStatus({ minted: mint.minted, message: mint.message, action: mint.action})
            setMintTriggered(false)
            return 
        }
    }

    const initalizeFreeMintBundle = async () => { 
        setMintTriggered(true)

        const price = await convertMatic("0")

        const providerId = getConnectedProvider()
        if(providerId == "Torus"){
            const mint = await torusController.handleFreeMintBundle(price)
            setMintStatus({ minted: mint.minted, message: mint.message, action: mint.action})
            setMintTriggered(false)
            return 
        }

        if(providerId == "MetaMask"){
            const mint = await metamaskController.handleFreeMintBundle(price)
            setMintStatus({ minted: mint.minted, message: mint.message, action: mint.action, fullMessage: mint.fullMessage})
            setMintTriggered(false)
            return 
        }
        
        if(providerId == "CoinBase"){
            const mint = await coinBaseController.handleFreeMintBundle(price)
            setMintStatus({ minted: mint.minted, message: mint.message, action: mint.action})
            setMintTriggered(false)
            return 
        }

        if(providerId == "WalletConnect"){
            const mint = await WSController.handleFreeMintBundle(price)
            setMintStatus({ minted: mint.minted, message: mint.message, action: mint.action})
            setMintTriggered(false)
            return 
        }
    }

    const initalizeAllowListMint = async () => { 
        setMintTriggered(true)

        const price = await convertMatic(CONTRACT_MINT_BUNDLE_PRICE)

        const providerId = getConnectedProvider()
        if(providerId == "Torus"){
            const mint = await torusController.handleAllowListMintBundle(price)
            setMintStatus({ minted: mint.minted, message: mint.message, action: mint.action})
            setMintTriggered(false)
            return 
        }

        if(providerId == "MetaMask"){
            const mint = await metamaskController.handleAllowListMintBundle(price)
            setMintStatus({ minted: mint.minted, message: mint.message, action: mint.action, fullMessage: mint.fullMessage})
            setMintTriggered(false)
            return 
        }
        
        if(providerId == "CoinBase"){
            const mint = await coinBaseController.handleAllowListMintBundle(price)
            setMintStatus({ minted: mint.minted, message: mint.message, action: mint.action})
            setMintTriggered(false)
            return 
        }

        if(providerId == "WalletConnect"){
            const mint = await WSController.handleAllowListMintBundle(price)
            setMintStatus({ minted: mint.minted, message: mint.message, action: mint.action})
            setMintTriggered(false)
            return 
        }
    }

    const initializeFreeMint = async () => { 
        setMintTriggered(true)

        const price = await convertMatic("0")

        const providerId = getConnectedProvider()
        if(providerId == "Torus"){
            const mint = await torusController.handleFreeMint(price)
            setMintStatus({ minted: mint.minted, message: mint.message, action: mint.action})
            setMintTriggered(false)
            return 
        }

        if(providerId == "MetaMask"){
            const mint = await metamaskController.handleFreeMint(price)
            setMintStatus({ minted: mint.minted, message: mint.message, action: mint.action, fullMessage: mint.fullMessage})
            setMintTriggered(false)
            return 
        }
        
        if(providerId == "CoinBase"){
            const mint = await coinBaseController.handleFreeMint(price)
            setMintStatus({ minted: mint.minted, message: mint.message, action: mint.action})
            setMintTriggered(false)
            return 
        }

        if(providerId == "WalletConnect"){
            const mint = await WSController.handleFreeMint(price)
            setMintStatus({ minted: mint.minted, message: mint.message, action: mint.action})
            setMintTriggered(false)
            return 
        }
    }


    const initalizeMintBundle = async () => { 
        setMintTriggered(true)

        const price = await convertMatic(CONTRACT_MINT_BUNDLE_PRICE)
        console.log('Bundle Price', price)

        const providerId = getConnectedProvider()
        if(providerId == "Torus"){
            const mint = await torusController.handleMintBundle(price)
            setMintStatus({ minted: mint.minted, message: mint.message, action: mint.action})
            setMintTriggered(false)
            return 
        }

        if(providerId == "MetaMask"){
            const mint = await metamaskController.handleMintBundle(price)
            setMintStatus({ minted: mint.minted, message: mint.message, action: mint.action, fullMessage: mint.fullMessage})
            setMintTriggered(false)
            return 
        }
        
        if(providerId == "CoinBase"){
            const mint = await coinBaseController.handleMintBundle(price)
            setMintStatus({ minted: mint.minted, message: mint.message, action: mint.action})
            setMintTriggered(false)
            return 
        }

        if(providerId == "WalletConnect"){
            const mint = await WSController.handleMintBundle(price)
            setMintStatus({ minted: mint.minted, message: mint.message, action: mint.action})
            setMintTriggered(false)
            return 
        }
    }





    useEffect(() => {
        const pollForMintedCard = async () => {
            setFetchingMintedCard(true)

            while (true) {
                try {
                    const response = await getRecentlyMinted()
                    
                    if (response.status === 200) {
                        updateMintedCards(response.data)
                        break // Break the loop if status is 200
                    }
                } catch (error) {
                    console.error("Error loading recent minted card(s):", error)
                    setMintStatus({...mintStatus, action: true, message: 'Error Loading Recent Minted Card'})
                }
                await new Promise(resolve => setTimeout(resolve, 15000)) // Wait before retrying (x ) intervals
            }

            setFetchingMintedCard(false)
        }

        if(mintStatus.minted){
            pollForMintedCard()
        }

    }, [mintStatus.minted])


    useEffect(() => { 
    

       const handleTotalMintPrice = async () => { 
            const baseCost = await convertMatic(CONTRACT_MINT_PRICE)
            const totalCost = baseCost * mintQuantity
            setMintPrice(totalCost.toString())  

            // reable mint price 
            const readablePrice = await converMaticToPrice(totalCost.toString())
            setReadableMintPrice(readablePrice)
       }



       handleTotalMintPrice()

    }, [mintQuantity])

    useEffect(() => { 
        const checkSaleStatus = async () => { 
            if(status){
                const mintBundleState = await checkBundleMintStatus()
                const freeMintClaimed = await checkFreeMintClaimed()
                const freeMintBundleClaimed = await checkFreeMintBundleClaimed()
                const allowListClaimed = await checkAllowListClaimed()
                console.log('Mint Bundle Status', mintBundleState)
                setMintBundleStatus(mintBundleState)
                setFreeMintBundleClaimed(freeMintBundleClaimed)
                setFreeMintClaimed(freeMintClaimed)
                setALlowListClaimed(allowListClaimed)
            }
        }

        checkSaleStatus()
    }, [status])

    
    console.log('New Mint Price', mintPrice)
    console.log('Mint Quanitity', mintQuantity)

    return ( 
        
        <Dialog 
            fullScreen
            open={status}
            onClose={handler}
            TransitionComponent={BRFTransition}>
                
                <AppBar sx={{ position: 'relative'}}>
                    <Toolbar>

                        <IconButton 
                            edge="start"
                            color="inherit"
                            onClick={handler}
                            aria-label="close">
                                <CloseIcon/>
                        </IconButton>

                        <BRFTypography text="Mint"/>

                    </Toolbar>
                </AppBar>

                <DialogContent
                    sx={{ backgroundColor: '#0f111f',  maxWidth: '100%'}}>


                {!mintStatus.minted ? 
                    <Stack
                        direction="column"
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        display="flex"
                        mt={4}>

                          
                             <Stack 
                                spacing={2} 
                                alignItems="center" 
                                sx={{color: '#FFFFFF'}}>

                               <Button
                                 variant="outlined"
                                 color="info"
                                 onClick={() => handleToggleMintOptionView()}>
                                    {toggleMintOptionView ? 'Close Quanity View': 'Mint Quanity'}
                               </Button>

                               {!toggleMintOptionView && 
                                    <Stack 
                                        direction="column"
                                        spacing={2}
                                        justifyContent="center" 
                                        alignItems="center" 
                                        display="flex">

                                                 {!freeMintBundleClaimed && !mintBundleStatus &&  
                                                    <MintWhiteListButton
                                                        onClick={() => initalizeFreeMintBundle()}
                                                        text="Mint Free Bundle"/>
                                                }

                                    
                                            {mintBundleStatus && 
                                             <Stack 
                                                direction="column"
                                                spacing={2}
                                                justifyContent="center" 
                                                alignItems="center" 
                                                display="flex">
                                                {isAllowListClaimed ? 
                                                    <MintButton 
                                                        onClick={() => initalizeMintBundle()}
                                                        text={"Mint Bundle"}/>
                                                    :
                                                    <MintButton 
                                                        onClick={() => initalizeAllowListMint()}
                                                        text={"Allow List Mint"}/>
                                                }
                                            </Stack>
                                            }
                                    </Stack> 
                                }

                                {toggleMintOptionView && 
                                <BRFPanel shadow={3}>
                                    <Stack 
                                        direction={"column"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        display="flex"
                                        spacing={2}>

                                        <Typography 
                                                mt={4}
                                                variant="h4" 
                                                fontFamily="Roboto Slab"
                                                color="white">
                                                    Mint Bull Cards
                                            </Typography>
                                        <Stack 
                                            mt={3}
                                            mb={3}
                                            direction="row" 
                                            spacing={1} 
                                            alignItems="center">

                                            <IconButton
                                                onClick={decrementMintQuantity} 
                                                disabled={allowedDecrement}
                                                style={{ fontSize: '4rem'}}
                                                >
                                                <RemoveCircleOutlineIcon
                                                fontSize='4rem'
                                                color="error"
                                            />
                                            </IconButton>


                                            <Typography 
                                                fontFamily="Roboto Slab"
                                                variant="h2" 
                                                color="white"
                                                ml={5}
                                                mr={5}>
                                                    {mintQuantity}
                                            </Typography>

                                            <IconButton
                                                size='large'
                                                onClick={incrementMintQuantity}
                                                disabled={allowedIncrement}
                                                style={{ fontSize: '4rem',}}>
                                                <AddCircleOutlineIcon
                                                    fontSize='4rem'
                                                    color="success"/>
                                            </IconButton>
                                        </Stack>

                                        <Typography
                                            mb={5}
                                            fontSize={"4rem"}
                                            color="GrayText">
                                            {readableMintPrice}
                                        </Typography>

                                       {!mintTriggered ? 

                                        <Stack 
                                            direction={"column"}
                                            justifyContent={"center"}
                                            alignItems={"center"}
                                            display="flex"
                                            spacing={2}>

                                            <MintButton 
                                                onClick={() => initializeMint()}
                                                text={'Mint'}>
                                            </MintButton>

                                        {!freeMintClaimed  &&  
                                            <MintWhiteListButton
                                                onClick={() => initializeFreeMint()}
                                                text={'Free Mint'}>
                                            </MintWhiteListButton>
                                        }
                                        </Stack>
                                            :
                                            <CircularProgress
                                                color="secondary"/>
                                        }

                                    </Stack>
                                    </BRFPanel>
                                }                          
                             </Stack>
                            

                    </Stack>      
                    
                    : 

                    <Stack
                        direction="column"
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        display="flex"
                        mt={4}>
                        
                        {fetchingMintedCard ? 

                        <Stack
                            direction="column"
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            display="flex"
                            mt={2}>

                           
                            <Typography
                                variant="h6"
                                fontFamily="Roboto Slab"
                                sx={{
                                    color: '#66fcf1'
                                }}>
                                    Loading Recently Minted Cards 
                            </Typography>  

                            <Typography
                                variant="p"
                                fontFamily="Roboto Slab"
                                sx={{
                                    color: '#66fcf1'
                                }}>
                                    Newly Minted Card Takes a while. Please wait..
                            </Typography>  

                            <CircularProgress
                                    color="secondary"/>

                                
                        </Stack>

                        
                            :

                            <Stack
                                direction="column"
                                spacing={2}
                                justifyContent="center"
                                alignItems="center"
                                display="flex"
                                mt={4}>
                                
                                { 
                                    mintedCards && 
                                    mintedCards.length > 0 && 
                                    mintedCards.map((card, index) => ( 
                                        <BullCard
                                            key={`${card.tokenId}-${index}`}
                                            data_handler={card}/>
                                    ))

                                   
                                }   

                            </Stack>
                        
                        }

                    </Stack>
                }

                </DialogContent>

                <MintInfo
                    status={mintStatus.action}
                    handler={handleMintInfo}
                    message={mintStatus.message}
                    fullMessage={mintStatus.fullMessage}/>
        </Dialog>     
    )
}

export default MintDialog 