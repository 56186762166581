
import React, { useEffect, useState } from 'react'

import { BrowserRouter, Routes, Route } from "react-router-dom"

import { Web3ReactProvider } from '@web3-react/core'
import { getLibrary } from './utils/BRFSessionUtils'

import BullNav from './components/BullNav'
import { getConnectionStatus } from './session/WalletSession'

import Faq from './pages/homescreen/FAQ'

import CoinBaseController from './session/walletsession/CoinBaseController'
import MetaMaskController from './session/walletsession/MetaMaskController'
import TorusController from './session/walletsession/TorusController'
import MobileController from './session/walletsession/WSController'

import BullRush_bg from './images/bullrush_bg.jpg'
import { height } from '@mui/system'

export const WSController = new MobileController()
export const coinBaseController = new CoinBaseController()
export const metamaskController = new MetaMaskController()
export const torusController = new TorusController()

const BullApp = () => {


    const [authStatus, setAuthStatus] = useState(false) 



    useEffect(() => {

        const isWalletConnected = () => {
            const status = getConnectionStatus()
            setAuthStatus(status) 
        }

        const checkAuth = setInterval(() => { 
            isWalletConnected() 
        }, 10)
        return () => clearInterval(checkAuth) 


     }, [authStatus])


    return (
       
        <BrowserRouter>
            <Web3ReactProvider getLibrary={getLibrary}>
                <BullNav isAuthenticated={authStatus} />
            </Web3ReactProvider>
        </BrowserRouter>
                    
    )
}

export default BullApp