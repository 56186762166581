import React, { useState } from 'react'


import { Grow, Paper } from '@mui/material'


import ReactCardFlip from 'react-card-flip'

import BackCard from './BackCard'
import FrontCard from './FrontCard'
import { Box } from '@mui/system'
import { isMobile } from '../../utils/MobileUtils'







const BullCard = ({data_handler, from}) => {
    /*

        - Extra data from backend that equals the token id of the card
        - display data 

    */
    const [isFlipped, setIsFlipped] = useState(false) 
    

    const handleFlip = () => {
        setIsFlipped(!isFlipped)
    }

    const cardStat = () => { 
        let leased = data_handler.brfcard_leasing.is_leased 
        if(from=="team"){
            if(leased ){
                return 'leased_card bevel'
            }
            return 'owned_card bevel'
        }

        if(from="market"){ 
            return "market_card bevel"
        }
    }

    console.log(`Testing BullCard ${cardStat()}`)


    return ( 
        <Box
            mt={5}
            display="flex"
            height="100%"
            width="350px"
            justifyContent="center"
            alignItems="center"
            sx={{
                marginBottom: 2,
            }}>
            <Grow
                in={true}
                style={{ transformOrigin: '0 0 0'}}
                {...(true ? { timeout: 3000 }: {} )}>

                <Paper
                    elevation={3}
                    className={cardStat()}
                    sx={{
                        maxHeight: isMobile() ? '100%' : '100%',  
                        maxWidth:  isMobile() ? '307px' :'270px',
                        display: 'flex', 
                        justifyContent: 'center', 
                        p: '3px 2px 3px 6px '}}>
                    <ReactCardFlip 
                        isFlipped={isFlipped}
                        flipDirection="vertical"
                        containerClassName={cardStat()}
                        >
                        
                        <FrontCard handler={handleFlip} card_image_uri={data_handler.image}/>
                        <BackCard handler={handleFlip} stat_data={data_handler.card_attributes}  card_details={data_handler}/>

                    </ReactCardFlip>
                </Paper>
            </Grow>
        </Box> 
    )
}

export default BullCard